@media only screen and (min-width: 800px) {
    div[role="dialog"] {
        left: calc((100vw - 800px) / 2);
    }
}


:root {
    --rsbs-max-w: 800px;
}

div[data-rsbs-backdrop="true"] {
    display: none;
}

/* Fix for white area on chrome on ios */
div[data-rsbs-state="open"] div[data-rsbs-content="true"] {
    height: 100%;
}

div[role="dialog"] {
    /* overflow: visible is a hotfix for safari. Header could become out of bounds of the modal. */
    overflow: visible;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}