.animate-in {
    opacity: 1 !important;
}

.animated-tab {
    position: absolute;
    width: 100%;
    transition: transform 0.250s ease, top 0.250s ease, height 0.250s ease, opacity 0.200s ease;
    display: flex;
    flex-direction: column;
}

.animate-out-right {
    overflow: hidden;
    transform: translateX(100%);
    opacity: 0;
}

.animate-out-left {
    overflow: hidden;
    transform: translateX(-100%);
    opacity: 0;
}

.no-start-offset {
    top: 0 !important;
    height: 100% !important;
}

.fullheight {
    height: 100%;
}

.white-shadow::after {
    border-radius: 8px 8px 0 0;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    box-shadow: inset 0 60px 14px -10px #ffffff;
    z-index: 1;
}

[data-rsbs-header="true"] {
    box-shadow: unset;
}